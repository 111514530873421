import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  //   ListItem,
  //   OrderedList,
  //   UnorderedList,
  Button,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import BaseLayout from '../../layout/base'
import {
  Heading1,
  Heading2,
  //   Heading3,
  //   Heading4,
  //   Heading5,
  //   Heading6,
  Paragraph,
} from '../../components/ContentWithStyles/TransformToChakra'
import SEO from '../../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const [word, setWord] = useState('Palabra')
  const selectRandomItem = someList =>
    someList[Math.floor(Math.random() * someList.length)]
  useEffect(() => {
    setWord(selectRandomItem(data.wordsJson.spanish.verbs))
  }, [data.wordsJson.spanish.verbs])

  return (
    <BaseLayout>
      <SEO
        title="Generador de verbos aleatorios"
        titleSeo="Generador de verbos aleatorios"
        description="Herramienta para generar verbos al azar en español."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[{ name: 'Palabras', url: '/palabras/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {word}
            </Text>
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de verbos aleatorios
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() =>
                setWord(selectRandomItem(data.wordsJson.spanish.verbs))
              }
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>Generador de verbos aleatorios en español</Heading1>
        <Paragraph>Generador de verbos aleatorios en español</Paragraph>
        <Paragraph>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur
          reprehenderit, error quibusdam itaque officia necessitatibus nesciunt
          aperiam odit sed hic exercitationem repudiandae est assumenda eaque in
          ratione quasi qui illo officiis tempora. Cum, dignissimos natus iusto
          unde repudiandae odit placeat sint quaerat esse aliquam ipsa
          voluptate. Cupiditate ut delectus illum.
        </Paragraph>
        <Heading2>Generar de verbos al azar en español</Heading2>
        <Paragraph>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Numquam,
          sint. Generar verbos en español al azar.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordsJson {
      spanish {
        verbs
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
